import React from "react";
import { IoOpenOutline } from "react-icons/io5";

import {
  Button,
  Container,
  Wrapper,
  Box,
  FlexButtons,
} from "../../../components/util";
import {
  IntegrationsPageHeader,
  WhatIsIntegration,
  WhatIsInvoxy,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import XeroLogo from "../../../images/logos/Xero/Xero_Blue_Single.svg";

const Xero = (props) => (
  <Layout>
    <Seo
      title="Xero Integration | Karmly"
      description="Invoices sent from Karmly create draft accounts receivable invoices in Xero, so you can manage your aged debtors and reconcile your accounts in no time."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="
      ACCOUNTING"
      title="Connect Karmly with Xero"
      description="Automatically send your invoices to your accounting software."
    />
    <Container>
      <Wrapper>
        <Box stackGap={40}>
          <Image
            filename="Xero_ConnectedAppLogo_Dark.png"
            alt="Karmly | Xero Connected App"
            centerImage
            maxWidth={140}
          />
          <Box className="-textCenter" stackGap={5}>
            <h4>
              Invoices sent from Karmly create draft accounts receivable
              invoices in Xero, so you can manage your aged debtors and
              reconcile your accounts in no time.
            </h4>
            <h4>
              Automatically assign revenue to different Xero account codes and
              create new Xero contacts from your clients in Karmly.
            </h4>
          </Box>
          <FlexButtons justify="center">
            <Button to="/recruitment/signup" className="primary -lg gtm-cta">
              Get Started
            </Button>
            <Button
              to="https://www.xero.com/nz/signup/?xtid=x30invoxy"
              className="secondary -lg"
              atag
              externalLink
            >
              Try Xero FREE{" "}
              <IoOpenOutline css={{ top: "2px", marginLeft: "5px" }} />
            </Button>
          </FlexButtons>
        </Box>
        <Image
          filename="Xero_Feature.png"
          alt="Xero + Karmly Integration"
          addShadow
        />
      </Wrapper>
    </Container>
    <WhatIsInvoxy />
    <WhatIsIntegration
      title="What is Xero?"
      description="Xero is easy to use online accounting software that’s designed specifically for small businesses."
      integration="Xero"
      link="https://www.xero.com/nz/signup/?xtid=x30invoxy"
      logo={XeroLogo}
      details={[
        "It’s all online, so you can work when and where you want to. Just login with your PC, Mac or mobile.",
        "Your bank statements are automatically imported and categorized, letting you see your cashflow in real-time.",
        "Invite your team and work together on financials. You can collaborate over your up-to-date numbers.",
        "Xero has all you need to run your business – including invoicing, paying bills, sales tax returns, reporting and much more.",
      ]}
    />
    <Container>
      <Wrapper stackGap={20}>
        <Image
          filename="Xero_ConnectedAppLogo_Dark.png"
          alt="Karmly | Xero Connected App"
          centerImage
          maxWidth={140}
        />
      </Wrapper>
    </Container>
  </Layout>
);

export default Xero;
